import * as React from 'react';
import {SvgIconComponent} from "@material-ui/icons";
import {Popover} from "@material-ui/core";

interface Option {
    value: string;
    label: string;
    icon?: SvgIconComponent;
    disabled?: boolean;
}

interface DropdownMenuProps {
    options: (Option | undefined)[];
    label: string;
    id?: string;
    onChange: (option: Option) => void;
    style?: React.CSSProperties;
    isSmOrBelow: boolean;
}

const DropdownMenu: React.ComponentType<DropdownMenuProps> = ({ options, label, id, onChange, isSmOrBelow}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOptionClick = (option : Option) => {
        setIsOpen(false);
        onChange(option);
    };

    const selectRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = (event : MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div style={{position: 'relative'}} ref={selectRef}>
            <div
                className={`dropdown ${isOpen ? 'open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
                id={id}
            >
                <div className="selected-option" style={{justifyContent: 'center'}}>
                    {(
                        <span className="dropdown-placeholder">{label}</span>
                    )}
                </div>

            </div>
            {isOpen && (
                <Popover
                    open={isOpen}
                    anchorEl={selectRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            position: 'absolute',
                            minWidth: 140,
                            top: '80%',
                            left: '-50%',
                            right: '50%',
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 5px #666666',
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            overflow: 'hidden',
                            zIndex: 1,
                            width: 'fit-content'
                        },
                    }}
                >
                    <div>
                        {options.map((option, index) => (
                            option &&
                            <div
                                key={option.value}
                                id={'dropdown-option-' + index}
                                className="option"
                                style={{padding: '8px 16px', alignItems: 'center', cursor: option.disabled ? 'default' : 'pointer',  opacity: option.disabled ? '.75' : '1'}}
                                onClick={() => {
                                    if(!option.disabled) {
                                        handleOptionClick(option)
                                    }
                                }}
                            >
                                {option.icon && (
                                    <span className="option-icon-left" style={{marginRight: isSmOrBelow ? '4px' : '8px'}}><option.icon/></span>
                                )}
                                {option.label}
                            </div>
                        ))}
                    </div>
                </Popover>
            )}
        </div>
    );
};

export default DropdownMenu;
