import * as React from 'react';
import AppBar from "../../appbar/AppBar";
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    InputLabel,
    Tooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import {SideBar} from "../../sidebar/SideBar";
import {
    ColoredLink,
    ErrorMessageSectionItem,
    SmallBr,
    Spacer,
    SuccessMessageSectionItem
} from "../../ui-components/StyledComponents";
import {BLACK, GREY, RED} from "../../../themes/colors";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    appContextActions,
    appContextSelector,
    forgotPassword,
    sortedAuthorizedLocationsSelector
} from "../../app-context/appContextSlice";
import {useNavigate, useParams} from "react-router-dom";
import {
    EmployeeDto,
    EmployeeLocation,
    EmployeeValidationKeys,
    getAccessLevel,
    getEmployeeGroupLabel,
    isEqual, isKioskAssociate
} from "../../../dtos/Employee";
import {
    employeeContextActions,
    employeeContextSelector,
    getEmployeeForEdit,
    saveEmployee,
    sendPortalInvite
} from "../employeeSlice";
import {useCurrentUserContext} from "../../app-context/CurrentUserContextContainer";
import {employeeGroupSortedSelector, getEmployeeGroups} from "../employeeGroupSlice";
import {AppWithFooter} from "../../../App";
import {KIOSK_EMPLOYEE, KIOSK_MANAGER, KIOSK_NONE, KIOSK_TEAM_LEAD} from "../../app-context/containers/UserPermissions";
import {
    nameContainsInvalidCharacters, isAlphanumeric,
    isEmailValid, isNumericStrict
} from "../../../util/TextUtils";
import {usePrompt} from "../../../util/Navigation";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {EmployeeDuplicateLink} from "../components/EmployeeDuplicateLink";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {getSortOrder} from "../../../dtos/EmployeeGroup";
import {selectBrandThunk} from "../../app-context/thunks/SelectBrandThunk";
import {LocationDto} from "../../../dtos/Location";
import {deviceTypeSelector, getDeviceTypes} from "../../devices/deviceTypeSlice";
import {
    devicePickupPermissionActions,
    devicePickupPermissionContextSelector,
    viewAllDevicePickupPermissions
} from "../../devices/devicePickupPermissionSlice";
import {EmployeeDevicePickupPermissionInput} from "../components/EmployeeDevicePickupPermissionInput";
import {DevicePickupPermissionDto, isPermissionsEqual} from "../../../dtos/DevicePickupPermission";
import {DialogAlert} from "../../ui-components/DialogAlert";
import '../../../css/EditEmployee.css';
import {ArrowBack, Error} from "@material-ui/icons";
import {Option} from "../../ui-components/ColorSelect";
import {EmployeeNumberComposition, isMDCDisabledBrand} from "../../../dtos/Brand";
import {EMPLOYEE_DASHBOARD} from "../../../dtos/Report";
import ErrorWrapper, {useFieldErrors} from "../../ui-components/ErrorWrapper";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCCheckbox} from "../../ui-components/design-system/ARCCheckbox";
import {ARCSelectInput} from "../../ui-components/design-system/ARCSelectInput";
import {ARCTextInput} from "../../ui-components/design-system/ARCTextInput";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, brandId, employeeId} = useParams();

    React.useEffect(() => {
        if(brandId && context.currentBrand?.brandId !== parseInt(brandId)) {
            dispatch(selectBrandThunk(parseInt(brandId)));
        }

        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(employeeId) {
                dispatch(getEmployeeForEdit({locationId: parseInt(locationId), brandId: brandId ? parseInt(brandId) : context.currentBrand?.brandId, employeeId: parseInt(employeeId)}));
                dispatch(viewAllDevicePickupPermissions({locationId: parseInt(locationId)}));
                dispatch(getDeviceTypes({locationId: parseInt(locationId), brandId: context.currentBrand?.brandId}));
            }
        }
        else if(employeeId && context.currentLocation?.locationId) {
            dispatch(getEmployeeForEdit({locationId: context.currentLocation.locationId, brandId: brandId ? parseInt(brandId) : context.currentBrand?.brandId, employeeId: parseInt(employeeId)}));
            dispatch(viewAllDevicePickupPermissions({locationId: context.currentLocation.locationId}));
            dispatch(getDeviceTypes({locationId: context.currentLocation.locationId, brandId: brandId ? parseInt(brandId) : context.currentBrand?.brandId}));
        }

    }, [brandId, context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, employeeId, locationId]);
}

export const EditEmployee = () => {
    useAutoSelectLocationByUrl();

    const {employeeId} = useParams();
    const [abortController, setAbortController] = React.useState<AbortController>(new AbortController());
    const abortControllerRefresh = React.useCallback(() => {
        setAbortController(new AbortController());
    }, []);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const currentUserContext = useCurrentUserContext();
    const employeeContext = useAppSelector(employeeContextSelector);
    const employeeGroupsMap = useAppSelector(employeeGroupSortedSelector);
    const authLocations = useAppSelector(sortedAuthorizedLocationsSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const devicePickupPermissionContext = useAppSelector(devicePickupPermissionContextSelector);
    const theme = useTheme();

    const [formError, setFormError] = React.useState<string | undefined>(undefined);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(true);

    //Should user receive 'Are you sure you want to leave this page?'?
    const [showAlertOnNav, setShowAlertOnNav] = React.useState<boolean>(false);

    //User clicked 'Resend Login Email' link, show confirm dialog
    const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(false);

    //User saved an existing employee with new info that qualifies for a portal account invite, show confirm dialog
    const [showSendInviteDialog, setShowSendInviteDialog] = React.useState<boolean>(false);

    const [updateBadgeId, setUpdateBadgeId] = React.useState<boolean>(false);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);
    const [showPortalAccess, setShowPortalAccess] = React.useState<boolean>(false);
    
    // cancel requests when navigating away from page
    React.useEffect(() => {
        return () => {
            abortController.abort()
            abortControllerRefresh();
        };
    },[abortController, abortControllerRefresh]);

    const stateEmployee = React.useMemo(() => {
        return employeeContext.selectedEmployee;
    }, [employeeContext.selectedEmployee]);


    const isEditingEmployee = React.useMemo(() => {
        return employeeId;
    }, [employeeId]);

    const canEdit = React.useMemo(() => {
        if (!stateEmployee) {
            return true;
        } else if (context.demoMode) {
          return false;
        } else if(currentUserContext.groups.isSSOHQUser) {
            return false;
        } else if (currentUserContext.groups.isArcTechSupport) {
            return false;
        } else if(!context.authorizationDto?.employee) {
            return true;
        } else if (context.currentBrand?.employeeAPIEnabled) {
            return false;
        } else if (currentUserContext.accessLevel >= getAccessLevel(stateEmployee)) {
            return true;
        }

        return false;
    }, [stateEmployee, context.demoMode, context.authorizationDto?.employee, context.currentBrand?.employeeAPIEnabled, currentUserContext.groups.isSSOHQUser, currentUserContext.groups.isArcTechSupport, currentUserContext.accessLevel]);

    const [formEmployee, setFormEmployee] = React.useState<EmployeeDto>(stateEmployee ?? {
        employeeId: undefined,
        firstName: '',
        middleName: undefined,
        lastName: '',
        email: undefined,
        employeeNumber: '',
        badgeId: '',
        employeeGroups: employeeGroupsMap?.filter(group => group.groupName === KIOSK_EMPLOYEE),
        employeeHistoryList: [],
        employeeLocations: context.currentLocation && context.currentLocation.locationId !== -1 ? [{location: context.currentLocation as LocationDto, active: true}] :
            (context.authorizationDto?.authorizedLocations.length === 1) ? [{location: context.authorizationDto.authorizedLocations[0], active: true}] : [],
        employeePortalId: undefined,
        lastPortalInviteDate: undefined,
        sendInvite: true,
        devicePickupPermission: devicePickupPermissionContext.permissions.find(group => group.default) ?? {
            devicePickupPermissionId: undefined,
            permissionName: '',
            allowMultipleCheckouts: false,
            default: false,
            custom: false,
            deviceTypes: deviceTypeContext.deviceTypes.length > 0 ? [{
                devicePickupPermissionDeviceTypeId: undefined,
                deviceTypeId: deviceTypeContext.deviceTypes.length === 1 ? deviceTypeContext.deviceTypes[0].deviceTypeId : undefined,
                quantity: 1
            }] : []
        },
        reasonForChange: undefined
    });

    const {fieldErrors,
        setFieldErrors,
        createErrorProps,
        setFieldError,
        clearFieldError,
        clearAllErrors} = useFieldErrors();

    const [forceHidePermissionInput, setForceHidePermissionInput] = React.useState<boolean>(false);

    const [showChangePermissionGroupDialog, setShowChangePermissionGroupDialog] = React.useState<boolean>(false);
    const [duplicatePermission, setDuplicatePermission] = React.useState<DevicePickupPermissionDto | undefined>(undefined);

    const [showCreatePermissionGroupDialog, setShowCreatePermissionGroupDialog] = React.useState<boolean>(false);

    const allowDevicePickupPermissionEdit = React.useMemo(() => {
        return !isMDCDisabledBrand(context.currentBrand?.brandId) && canEdit;
    }, [canEdit, context.currentBrand?.brandId]);

    const [createPermissionGroupName, setCreatePermissionGroupName] = React.useState<{value: string, error: string}>({value: '', error: ''});

    const [newBadge, setNewBadge] = React.useState<string>('');

    const formUpdated = React.useCallback((newPermission?: DevicePickupPermissionDto) => {
        if(stateEmployee) {
            const compareOld = {...stateEmployee, reasonForChange: undefined};
            const compareNew = {...formEmployee, devicePickupPermission: newPermission ? newPermission : formEmployee.devicePickupPermission, reasonForChange: undefined};

            return !isEqual(compareOld, compareNew);
        }
            // return JSON.stringify(compareOld) !== JSON.stringify(compareNew);
    }, [formEmployee, stateEmployee]);

    React.useEffect(() => {
        if(stateEmployee) {
            const activeLocations = stateEmployee.employeeLocations.filter(location => location.active);
            const activeAtOneLocation = activeLocations && activeLocations.length === 1;
            const activeLocation = activeAtOneLocation ? context.authorizationDto?.authorizedLocations.find(loc => loc.locationId === activeLocations[0].location.locationId) : undefined;

            const useDefault = !stateEmployee.devicePickupPermission && isKioskAssociate(stateEmployee) && activeAtOneLocation;
            const defaultAvailable = !!activeLocation?.defaultDevicePickupPermission;


            if(useDefault && defaultAvailable) {
                setFormEmployee({
                    ...stateEmployee,
                    devicePickupPermission: activeLocation?.defaultDevicePickupPermission
                })
            } else {
                setFormEmployee(stateEmployee);
            }
        }
    }, [context.authorizationDto?.authorizedLocations, context.currentBrand, context.currentLocation, stateEmployee]);


    React.useEffect(() => {
        dispatch(getEmployeeGroups({arg: undefined}));
    }, [dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch(employeeContextActions.resetSaveEmployee());
            dispatch(devicePickupPermissionActions.resetPermissionsList());
            dispatch(appContextActions.resetForgotPassword());
        }
    }, [dispatch]);

    const formChanged = React.useMemo(() => {
        return (updateBadgeId && newBadge !== '') || formUpdated();
    }, [formUpdated, newBadge, updateBadgeId]);

    const currentKioskGroup = React.useMemo(() => {
        return formEmployee.employeeGroups.find(group => group.kioskGroup);
    }, [formEmployee.employeeGroups]);

    const currentPortalGroup = React.useMemo(() => {
        return formEmployee.employeeGroups.find(group => !group.kioskGroup);
    }, [formEmployee.employeeGroups]);

    const hasNoKioskAccess = React.useMemo(() => {
        return !currentKioskGroup || currentKioskGroup.groupName === KIOSK_NONE;
    }, [currentKioskGroup]);
    
    const saveDisabled = React.useMemo(() => {
        if(context.demoMode) {
            return true;
        }

        if (employeeContext.saveProcessing || (!!formEmployee.employeeId && !!stateEmployee && !formChanged)) {
            return true;
        }
        if (!isEditingEmployee) {
            if (formEmployee.firstName?.length === 0 || formEmployee.lastName?.length === 0) {
                return true;
            }
            if (context.currentBrand?.badgeValidation) {
                if (formEmployee.badgeId?.length === 0 && currentKioskGroup?.groupName !== KIOSK_NONE) {
                    return true;
                }
            } else {
                if (!!formEmployee.employeeId) {
                    return true;
                }
            }
            if ((context.authorizationDto?.employee || currentUserContext.groups.isArcTechSupport ? canEdit : true) && (formEmployee.employeeGroups.some(group => !group.kioskGroup) || showPortalAccess)) {
                if (formEmployee.employeeGroups.some(group => !group.kioskGroup)) {
                    if (formEmployee.email?.length === 0) {
                        return true;
                    }
                }
            }
            
        }
    }, [context.demoMode, context.currentBrand?.badgeValidation, context.authorizationDto?.employee, employeeContext.saveProcessing, formEmployee.employeeId, formEmployee.firstName?.length, formEmployee.lastName?.length, formEmployee.employeeGroups, formEmployee.badgeId?.length, formEmployee.email?.length, stateEmployee, formChanged, isEditingEmployee, currentUserContext.groups.isArcTechSupport, canEdit, showPortalAccess, currentKioskGroup?.groupName])

    const kioskGroupOptions = React.useMemo(() => {
        const groups : Option[] = employeeGroupsMap.filter(group => group.kioskGroup).sort((g1, g2) => getSortOrder(g1.groupName) - getSortOrder(g2.groupName)).map((group) => {
            return {
                value: group.employeeGroupId,
                label: getEmployeeGroupLabel(group.groupName)
            }
        });

        return groups;
    }, [employeeGroupsMap]);

    const portalGroupOptions = React.useMemo(() => {
        const noneGroup : Option = {
            label: getLocalizedString('employee.portalGroup.none', 'None'),
            value: undefined
        };

        const groups : Option[] = employeeGroupsMap.filter(g => !g.kioskGroup).map(group => {
            return {
                value: group.employeeGroupId,
                label: getEmployeeGroupLabel(group.groupName),
                disabled: context.authorizationDto?.employee ? (!canEdit || currentUserContext.groups.isPortalAdmin || (canEdit && group.accessLevel > currentUserContext.accessLevel)) : false
            }
        });

        groups.push(noneGroup);

        return groups;
    }, [canEdit, context.authorizationDto?.employee, currentUserContext.accessLevel, currentUserContext.groups.isPortalAdmin, employeeGroupsMap]);

    const activeEmployeeLocations = React.useMemo(() => {
        return formEmployee.employeeLocations.slice().filter(loc => loc.active);
    }, [formEmployee.employeeLocations]);

    const onlyActiveLocation = React.useMemo(() => {
        return activeEmployeeLocations.length === 1 ? activeEmployeeLocations[0] : undefined;
    }, [activeEmployeeLocations]);

    const activeAtMultipleLocations = React.useMemo(() => {
        return activeEmployeeLocations.length > 1;
    }, [activeEmployeeLocations.length]);

    const showDevicePickupPermissionInput = React.useMemo(() => {
        return activeAtMultipleLocations || devicePickupPermissionContext.permissions.length > 0;
    }, [activeAtMultipleLocations, devicePickupPermissionContext.permissions.length]);

    React.useEffect(() => {
        if(onlyActiveLocation) {
            dispatch(viewAllDevicePickupPermissions({
                locationId: onlyActiveLocation.location.locationId
            }));

            dispatch(getDeviceTypes({locationId: onlyActiveLocation.location.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, dispatch, onlyActiveLocation]);

    React.useEffect(() => {
        if(activeAtMultipleLocations) {
            setFormEmployee(prevState => ({
                ...prevState,
                devicePickupPermission: undefined
            }))
        }
    }, [activeAtMultipleLocations]);

    const employeeLocationClicked = React.useCallback((index: number) => {
        let item = authLocations[index];
        let employeeLocations = formEmployee.employeeLocations.slice();
        let employeeLocation = employeeLocations.find(l => l.location.locationId === item.locationId);

        if(employeeLocation) {
            const activeLocations = employeeLocations.filter(loc => loc.location.locationId !== item.locationId && loc.active);

            if(activeLocations.length === 1 && isKioskAssociate(formEmployee)) {
                setFormEmployee({
                    ...formEmployee,
                    devicePickupPermission: activeLocations[0].location.defaultDevicePickupPermission,
                    employeeLocations: formEmployee.employeeLocations.map(el => el.location.locationId === item.locationId ? {...el, active: !el.active} : el)
                })
            } else {
                setFormEmployee({
                    ...formEmployee,
                    devicePickupPermission: undefined,
                    employeeLocations: formEmployee.employeeLocations.map(el => el.location.locationId === item.locationId ? {
                        ...el,
                        active: !el.active
                    } : el)
                });
            }
        }
        else {
            if (item) {
                const newLocation: EmployeeLocation = {
                    location: item,
                    active: true
                }
                setFormEmployee({
                    ...formEmployee,
                    devicePickupPermission: undefined,
                    employeeLocations: [
                        ...formEmployee.employeeLocations,
                        newLocation
                    ]
                });
            }
        }
    }, [authLocations, formEmployee]);

    const checkAllLocations = React.useCallback((status: boolean) => {
        //check/add all locations
        let updatedLocations = [...formEmployee.employeeLocations];

        authLocations.forEach((loc) => {
            //check if the form employee already has this location
            let employeeLocation = formEmployee.employeeLocations.find(el => el.location.locationId === loc.locationId);

            //if employee already at this location, update active status, else add this location to the employee
            if(employeeLocation) {
                updatedLocations = updatedLocations.map(el => el.location.locationId === employeeLocation?.location.locationId ? {...el, active: status} : el);
            }
            else {
                if(status) {
                    updatedLocations = [...updatedLocations, {location: loc, active: true}];
                }
            }
        });

        setFormEmployee({
            ...formEmployee,
            devicePickupPermission: undefined,
            employeeLocations: updatedLocations
        });

    }, [authLocations, formEmployee]);

    const computedEmployeeNumber = React.useMemo(() => {
        let updatedEmpNum;
        if (formEmployee.employeeGroups.some(g => g.groupName === KIOSK_NONE)) {
            return formEmployee.employeeId ? formEmployee.badgeId : '';
        } else if(newBadge) {
            updatedEmpNum = newBadge;
        } else {
            updatedEmpNum = context.currentBrand?.badgeValidation ? formEmployee.badgeId : formEmployee.employeeNumber;
        }

        if (context.currentBrand?.employeeNumberPrefix) {
            if (updatedEmpNum?.startsWith(context.currentBrand.employeeNumberPrefix)) {
                return updatedEmpNum;
            } else {
                return context.currentBrand.employeeNumberPrefix + updatedEmpNum;
            }
        } else {
            return updatedEmpNum;
        }
    }, [context.currentBrand?.badgeValidation, context.currentBrand?.employeeNumberPrefix, formEmployee.badgeId, formEmployee.employeeGroups, formEmployee.employeeId, formEmployee.employeeNumber, newBadge]);

    const validateEmployeeNumber = React.useCallback((input: string) => {
        const label = getLocalizedString('employee.badgeId', 'Badge ID')
        if (input?.length > 0) {
            if (context.currentBrand?.employeeNumberComposition === EmployeeNumberComposition.ALPHANUMERIC) {
                if (!isAlphanumeric(input)) {
                    return getLocalizedString('employee.employeeNumber.composition.alphanumericFull', 'Must only contain numbers and letters')
                }
            }
            if (context.currentBrand?.employeeNumberComposition === EmployeeNumberComposition.NUMERIC) {
                if (!isNumericStrict(input.substring(context.currentBrand.employeeNumberPrefix?.length ?? 0))) {
                    return getLocalizedString('employee.employeeNumber.composition.numericFull', 'Must only contain numbers')
                }
            }
        }
        
        if(context.currentBrand?.employeeNumberLengthMin) {
            const minLength: number = context.currentBrand.employeeNumberLengthMin;
            const maxLength: number | null = context.currentBrand.employeeNumberLengthMax;

            if (maxLength && (input?.length < minLength || input?.length > maxLength)) {
                if(minLength === maxLength) {
                    return getLocalizedString('employee.employeeNumber.invalidLength', `${label} must be ${minLength} characters`, [`${label}`, `${minLength}`]);
                } else {
                    return getLocalizedString('employee.employeeNumber.invalidMinAndMaxLength', `${label} must be between ${minLength} and ${maxLength} characters`, [`${label}`, `${minLength}`, `${maxLength}`]);
                }
            } else if (input?.length < minLength) {
                return getLocalizedString('employee.employeeNumber.invalidLengthMin', `${label} must be at least ${minLength} characters`, [`${label}`, `${minLength}`]);
            }
        }

        return '';
    }, [context.currentBrand?.employeeNumberLengthMin, context.currentBrand?.employeeNumberComposition, context.currentBrand?.employeeNumberPrefix?.length, context.currentBrand?.employeeNumberLengthMax]);
    
    const lockEmployeeNumber = React.useCallback((input: string) => {
        const label = getLocalizedString('employee.badgeId', 'Badge ID')
        if (input?.length > 0) {
            if (context.currentBrand?.employeeNumberComposition === EmployeeNumberComposition.ALPHANUMERIC) {
                if (!isAlphanumeric(input)) {
                    return getLocalizedString('employee.employeeNumber.composition.alphanumericFull', 'Must only contain numbers and letters')
                }
            }
            if (context.currentBrand?.employeeNumberComposition === EmployeeNumberComposition.NUMERIC) {
                if (!isNumericStrict(input.substring(context.currentBrand.employeeNumberPrefix?.length ?? 0))) {
                    return getLocalizedString('employee.employeeNumber.composition.numericFull', 'Must only contain numbers')
                }
            }

            if (context.currentBrand?.employeeNumberLengthMin) {
                const minLength: number = context.currentBrand.employeeNumberLengthMin;
                const maxLength: number | null = context.currentBrand.employeeNumberLengthMax;

                if (maxLength && (input?.length > maxLength)) {
                    if (minLength === maxLength) {
                        return getLocalizedString('employee.employeeNumber.invalidLength', `${label} must be ${minLength} characters`, [`${label}`, `${minLength}`]);
                    } else {
                        return getLocalizedString('employee.employeeNumber.invalidMinAndMaxLength', `${label} must be between ${minLength} and ${maxLength} characters`, [`${label}`, `${minLength}`, `${maxLength}`]);
                    }
                }
            }
        }

        return '';
    }, [context.currentBrand?.employeeNumberComposition, context.currentBrand?.employeeNumberLengthMin, context.currentBrand?.employeeNumberPrefix?.length, context.currentBrand?.employeeNumberLengthMax]);

    const employeeNumberOnChange = React.useCallback((value: string, fieldName: string) => {
        const error = lockEmployeeNumber(value);
        const label = context.currentBrand?.badgeValidation ? 'badgeId' : 'employeeNumber'
        if (error) {
            const showCharacter = !isAlphanumeric(value) && (fieldErrors[fieldName]?.error?.length ?? 0) === 0;
            if (showCharacter) {
                setFormEmployee({
                    ...formEmployee,
                    [label]: value.toUpperCase()
                })
            }
            setFieldError(fieldName, error)
        } else {
            setFormEmployee({
                ...formEmployee,
                [label]: value.toUpperCase()
            })
            clearFieldError(fieldName)
        }
    }, [lockEmployeeNumber, fieldErrors, setFormEmployee, formEmployee, clearFieldError, context.currentBrand?.badgeValidation, setFieldError])
    

    const validateFirstName = React.useCallback((firstName: string) => {
        if (nameContainsInvalidCharacters(firstName)) {
            return getLocalizedString('employee.firstName.specialCharacters', 'First Name cannot include special characters');    
        }
        if(firstName.length > 30) {
            return getLocalizedString('employee.firstName.maxLength', 'First Name cannot exceed 30 characters');
        }

        return undefined;
    }, []);

    const validateLastName = React.useCallback((lastName: string) => {
        if (nameContainsInvalidCharacters(lastName)) {
            return getLocalizedString('employee.lastName.specialCharacters', 'Last Name cannot include special characters');    
        }
        if(lastName.length > 30) {
            return getLocalizedString('employee.lastName.maxLength', 'Last Name cannot exceed 30 characters');
        }

        return undefined;
    }, []);
    
    const validateMiddleName = React.useCallback((middleName: string | undefined | null) => {
        if (!middleName) return undefined;
        if (nameContainsInvalidCharacters(middleName)) {
            return getLocalizedString('employee.middleName.specialCharacters', 'Middle Initial cannot include special characters');    
        }
        if (middleName.length > 1) {
            return getLocalizedString('employee.middleName.maxLength', 'Middle Initial cannot exceed 1 character');
        }

        return undefined;
    }, []);

    const validateEmployee = React.useCallback(() => {
        const label = context.currentBrand?.badgeValidation ? updateBadgeId ? EmployeeValidationKeys.newBadgeId : EmployeeValidationKeys.badgeId : EmployeeValidationKeys.employeeNumber;
        let hasErrors = false;

        let newFieldErrors : typeof fieldErrors = {};

        const firstNameError = validateFirstName(formEmployee.firstName);
        if(firstNameError) {
            newFieldErrors = ({...newFieldErrors, [`${EmployeeValidationKeys.firstName}`]: {
                error: firstNameError,
                shown: true
            }});
            hasErrors = true;
        }

        const lastNameError = validateLastName(formEmployee.lastName);
        if(lastNameError) {
            newFieldErrors = ({...newFieldErrors, [`${EmployeeValidationKeys.lastName}`]: {
                error: lastNameError,
                shown: true
            }});
            hasErrors = true;
        }
        
        const middleNameError = validateMiddleName(formEmployee.middleName);
        if(middleNameError) {
            newFieldErrors = ({...newFieldErrors, [`${EmployeeValidationKeys.middleName}`]: {
                error: middleNameError,
                shown: true
            }});
            hasErrors = true;
        }

        const employeeNumberError = validateEmployeeNumber(computedEmployeeNumber);
        if(!!employeeNumberError && !hasNoKioskAccess) {
            newFieldErrors = ({...newFieldErrors, [`${label}`]: {
                error: employeeNumberError,
                shown: true
            }});
            hasErrors = true;
        }

        if(hasErrors) {
            setFieldErrors(newFieldErrors);
        }

        return !hasErrors;
    }, [context.currentBrand?.badgeValidation, updateBadgeId, validateFirstName, formEmployee.firstName, formEmployee.lastName, formEmployee.middleName, validateLastName, validateMiddleName, validateEmployeeNumber, computedEmployeeNumber, hasNoKioskAccess, setFieldErrors]);

    const submitSaveEmployee = React.useCallback((sendInvite: boolean, createPermission: boolean, newPermission?: DevicePickupPermissionDto | undefined) => {
        if(context.currentLocation?.locationId && context.currentBrand) {
            setForceHidePermissionInput(true);

            if(formEmployee.employeeId && stateEmployee) {
                if(!formChanged) {
                    setFormError(getLocalizedString('employee.unmodified', 'In order to update a User Record, at least one change must be made to this page.'));

                    window.scrollTo(0, 0);
                    return;
                }
            }

            setFormError(undefined);


            dispatch(saveEmployee({newEmployee: {...formEmployee, devicePickupPermission: newPermission ? newPermission : formEmployee.devicePickupPermission, badgeId: computedEmployeeNumber, employeeNumber: computedEmployeeNumber, sendInvite: sendInvite}, locationId: context.currentLocation.locationId, brandId: context.currentBrand?.brandId, createPermission: createPermission}));
            window.scrollTo(0, 0);
        }
    }, [computedEmployeeNumber, context.currentBrand, context.currentLocation?.locationId, dispatch, formChanged, formEmployee, stateEmployee])

    const submitForm = React.useCallback((event : React.FormEvent) => {
        event.preventDefault();
        
        if(!validateEmployee()) {
            return;
        }
        clearAllErrors();
        setShowSuccessMessage(true);
        setShowAlertOnNav(false);

        // CISBOH-318
        // Check the employee in the state that has current values in the database
        // If this employee does not have a portal account and does not have an email or portal access in the database,
        // AND they have entered an email and set portal access while editing, show a popup to confirm sending a portal invite
        if(stateEmployee) {
            if (!stateEmployee.employeePortalId && (!stateEmployee.email || !stateEmployee.employeeGroups.some(group => !group.kioskGroup))) {
                if ((formEmployee.email && isEmailValid(formEmployee.email)) && formEmployee.employeeGroups.some(group => !group.kioskGroup)) {
                    setShowSendInviteDialog(true);

                    return;
                }
            }
        }

        if(formEmployee.devicePickupPermission?.custom) {
            const dupPerm = devicePickupPermissionContext.permissions.find(perm => isPermissionsEqual(perm, formEmployee.devicePickupPermission));

            if(dupPerm) {
                setShowChangePermissionGroupDialog(true);
                setDuplicatePermission(dupPerm);

                return;
            } else {
                setShowCreatePermissionGroupDialog(true);
                return;
            }
        }

        submitSaveEmployee(true, false);
    }, [devicePickupPermissionContext.permissions, formEmployee.devicePickupPermission, formEmployee.email, formEmployee.employeeGroups, stateEmployee, submitSaveEmployee, validateEmployee, clearAllErrors]);

    // const submitSaveBadgeId = React.useCallback((event : React.FormEvent) => {
    //     event.preventDefault();
    //
    //     dispatch(saveEmployee({newEmployee: {...formEmployee, devicePickupPermission: newPermission ? newPermission : formEmployee.devicePickupPermission, badgeId: computedEmployeeNumber, employeeNumber: computedEmployeeNumber, sendInvite: sendInvite}, locationId: context.currentLocation.locationId, brandId: context.currentBrand?.brandId, createPermission: createPermission}));
    // }, [computedEmployeeNumber, context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, formEmployee]);


    const changePermissionGroupPopupClicked = React.useCallback((changeGroup: boolean) => {
        let newPermission : DevicePickupPermissionDto | undefined = undefined;

        setShowChangePermissionGroupDialog(false);
        setDuplicatePermission(undefined);

        if(changeGroup) {
            newPermission = devicePickupPermissionContext.permissions.find(perm => isPermissionsEqual(perm, formEmployee.devicePickupPermission))
            submitSaveEmployee(true, false, newPermission);
        } else {
            submitSaveEmployee(true, true, newPermission);
        }


    }, [devicePickupPermissionContext.permissions, formEmployee.devicePickupPermission, submitSaveEmployee]);

    const createPermissionGroupPopupClicked = React.useCallback((event : React.FormEvent | undefined, createGroup: boolean) => {
        event && event.preventDefault();

        let newPermission : DevicePickupPermissionDto | undefined = undefined;
        if(createGroup && formEmployee.devicePickupPermission) {
            if(createPermissionGroupName.value) {
                if(devicePickupPermissionContext.permissions.find(perm => perm.permissionName === createPermissionGroupName.value)) {
                    setCreatePermissionGroupName(prevState => ({value: prevState.value, error: getLocalizedString('employee.devicePickupPermission.groupNameUnique', 'This group name already exists')}))
                    return;
                }

                newPermission = {
                    ...formEmployee.devicePickupPermission,
                    permissionName: createPermissionGroupName.value,
                    custom: false
                }


            } else {
                setCreatePermissionGroupName(prevState => ({value: prevState.value, error: getLocalizedString('required', 'Please fill out this field.')}))
                return;
            }
        }

        setShowCreatePermissionGroupDialog(false);
        submitSaveEmployee(true, true, newPermission);
    }, [createPermissionGroupName.value, devicePickupPermissionContext.permissions, formEmployee, submitSaveEmployee]);

    React.useEffect(() => {
        if(employeeContext.saveSuccessful) {
            if(formEmployee.employeeId) {
                setSuccessMessage(getLocalizedString('employee.updated', formEmployee.firstName + ' ' + formEmployee.lastName + ' edited successfully.', [formEmployee.firstName, formEmployee.lastName]));
                setUpdateBadgeId(false);
                if(employeeContext.selectedEmployee?.employeeId && context.currentLocation?.locationId) {
                    dispatch(getEmployeeForEdit({locationId: context.currentLocation?.locationId, brandId: context.currentBrand?.brandId, employeeId: employeeContext.selectedEmployee?.employeeId}));
                }
                if(stateEmployee) {
                    setFormEmployee(stateEmployee);
                }

                dispatch(employeeContextActions.resetSaveEmployee());
            } else {
                navigate(
                    `/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`,
                    {
                        state: {
                            editingFromEmployee: {
                                employeeId: stateEmployee?.employeeId ?? 0,
                                firstName: stateEmployee?.firstName ?? '',
                                lastName: stateEmployee?.lastName ?? '',
                                reasonForChange: stateEmployee?.reasonForChange
                            }
                        }
                    });
            }
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, employeeContext.saveSuccessful, employeeContext.selectedEmployee?.employeeId, formEmployee.employeeId, formEmployee.firstName, formEmployee.lastName, navigate, stateEmployee]);

    React.useEffect(() => {
        if(employeeContext.sendInviteSuccessful) {
            setSuccessMessage(getLocalizedString('employee.portalInviteSent', 'An email has been sent to ' + formEmployee.email + ' with a link to set up an account.', [formEmployee.email ?? '']));
            setFormEmployee({
                ...formEmployee,
                employeeHistoryList: stateEmployee?.employeeHistoryList
            })
        }
        if(context.forgotPasswordSuccessful) {
            setSuccessMessage(getLocalizedString('employee.resetpasswordSent', 'An email has been sent to ' + formEmployee.email + ' with instructions to reset their password.', [formEmployee.email ?? '']));
        }
    }, [context.forgotPasswordSuccessful, employeeContext.sendInviteSuccessful, formEmployee, navigate, stateEmployee?.employeeHistoryList]);

    usePrompt(getLocalizedString('employee.exitAlert', 'Are you sure you want to leave? Changes you made will not be saved.'), undefined, undefined, showAlertOnNav);

    return  (
    <AppWithFooter>
        <AppBar/>
        <SideBar/>
        <main className={'o-main'}>
            <div className={'l-wrap l-container--lg'}>

                <DialogAlert title={getLocalizedString('employee.devicePickupPermission.makeNewGroup', 'Do you plan to use these device permissions for others?')}
                             messages={[getLocalizedString('employee.devicePickupPermission.nameGroup', 'If so, please name the group.')]}
                             isOpen={showCreatePermissionGroupDialog}
                >

                    <form style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}} onSubmit={(event : React.FormEvent) => createPermissionGroupPopupClicked(event, true)}>
                        <label className={'dialog-input-label'}>
                            {getLocalizedString('employee.devicePickupPermission.groupName', 'Device Permissions Group Name')}
                            <span style={{color: RED}}>{'*'}</span>
                        </label>

                        {createPermissionGroupName.error &&
                        <label className={'dialog-input-error'}>
                            {createPermissionGroupName.error}
                        </label>}

                        <input required={true}
                               className={'dialog-input'}
                               id={'add-user-pickup-permission-custom-popup-group-name-input'}
                               type={'text'}
                               onChange={(event) =>
                                   setCreatePermissionGroupName(prevState => ({
                                       ...prevState,
                                       value: event.target.value
                                   })
                               )}
                               value={createPermissionGroupName.value}/>

                        <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                            <button id={'add-user-pickup-permission-custom-popup-save-new-group-button'} className={'dialog-alert-button confirm'} type={'submit'}>
                                {getLocalizedString('employee.devicePickupPermission.saveNewGroup', 'Save')}
                            </button>

                            <div id={'add-user-pickup-permission-custom-popup-decline-button'} className={'dialog-alert-button cancel'} onClick={() => createPermissionGroupPopupClicked(undefined, false)}>
                                {getLocalizedString('employee.devicePickupPermission.noThanks', 'No thanks')}
                            </div>
                        </div>

                    </form>

                </DialogAlert>

                <DialogAlert title={getLocalizedString('employee.devicePickupPermission.groupNameMatches', formEmployee.firstName + '\'s custom device permissions matches ' + (duplicatePermission?.permissionName ? 'the ' + duplicatePermission?.permissionName + ' Group' : ' an existing group'), [`${formEmployee.firstName}`, `${(duplicatePermission?.permissionName ? 'the ' + duplicatePermission?.permissionName + ' Group' : ' an existing group')}`])}
                             messages={[getLocalizedString('employee.devicePickupPermission.changeGroup', 'Change ' + (formEmployee.firstName ?? '') + ' to ' + (duplicatePermission?.permissionName ? duplicatePermission?.permissionName + ' Group' : 'existing group') + '?', [`${formEmployee.firstName}`, `${(duplicatePermission?.permissionName ? duplicatePermission?.permissionName + ' Group' : 'existing group')}`])]}
                             isOpen={showChangePermissionGroupDialog}
                             confirmButton={{id: 'add-user-pickup-permission-existing-popup-use-existing-button', label: getLocalizedString('employee.devicePickupPermission.changeGroupYes', 'Change to Group'), onClick: () => changePermissionGroupPopupClicked(true)}}
                             closeButton={{id: 'add-user-pickup-permission-existing-popup-ignore-existing-button', label: getLocalizedString('employee.devicePickupPermission.changeGroupNo', 'Don\'t Change'), onClick: () => changePermissionGroupPopupClicked(false)}}
                />

                {successMessage &&
                    <ServerMessage closable={{isOpen: showSuccessMessage, onClose: () => setShowSuccessMessage(false)}} variant={'success'}>
                        <SuccessMessageSectionItem>
                            {successMessage}
                        </SuccessMessageSectionItem>
                    </ServerMessage>}

                {employeeContext.saveErrorMessage &&
                <div style={{width: '100%'}}>
                    {employeeContext.saveErrorMessage.split('\r').map((error, key) => {
                        return (
                            error && error !== '' &&
                            <ServerMessage key={key} variant={'error'}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {
                                        error.split('\n').map((e2, k2) => {
                                                return e2 && e2 !== '' &&
                                                    <ErrorMessageSectionItem key={k2}>
                                                        <EmployeeDuplicateLink err={e2}/>
                                                    </ErrorMessageSectionItem>

                                            }
                                        )
                                    }
                                </div>
                            </ServerMessage>
                        )
                    })
                    }
                </div>
                }

                {employeeContext.sendInviteErrorMessage &&
                <ServerMessage variant={'error'}>
                    <ErrorMessageSectionItem>
                        {employeeContext.sendInviteErrorMessage}
                    </ErrorMessageSectionItem>
                </ServerMessage>
                }

                {context.forgotPasswordErrorMessage &&
                <ServerMessage variant={'error'}>
                    <ErrorMessageSectionItem>
                        {context.forgotPasswordErrorMessage}
                    </ErrorMessageSectionItem>
                </ServerMessage>
                }

                {formError &&
                <ServerMessage variant={'error'}>
                    <ErrorMessageSectionItem>
                        {formError}
                    </ErrorMessageSectionItem>
                </ServerMessage>
                }
                
                <form style={{display: 'flex', flexDirection: 'row'}} id={'employeeForm'} onSubmit={(event) => {submitForm(event)}}>
                        <IconButton id={'add-user-back-button'} color="primary" className={'back-button'} aria-label="back" onClick={() => {
                            navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                        }}>
                            <ArrowBack className={'back-button-arrow'}/>
                        </IconButton>
                        <Container className={'employee-root-container'} maxWidth="xs">
                            <div className={'o-heading--xl'} style={{paddingBottom: '15px', width: '100%'}}>
                                {isEditingEmployee ? (canEdit ? getLocalizedString('employee.edit', 'Edit User') : getLocalizedString('employee.view', 'View User')) : getLocalizedString('employee.create', 'Activate New User')}
                            </div>
                            
                            <br/>

                            <div className={'section-header employee-section-header'}>{getLocalizedString('employee.name', 'User Name')}</div>

                            <div className={'employee-input-container'}>
                                <div className={'employee-div-container'}>
                                    <ErrorWrapper {...createErrorProps(`${EmployeeValidationKeys.firstName}`)}>
                                        <ARCTextInput 
                                            id={'add-user-first-name-input'}
                                            size={'md'} 
                                            required={true} 
                                            value={context.demoMode ? getLocalizedString('user', 'User') : formEmployee.firstName}
                                            label={getLocalizedString('employee.firstName', 'First Name')} 
                                            onChange={(event) => {
                                                const error = validateFirstName(event);
                                                if(error) {
                                                    const showCharacter = nameContainsInvalidCharacters(event) && (fieldErrors[`${EmployeeValidationKeys.firstName}`]?.error?.length ?? 0) === 0;
                                                    if (showCharacter) {
                                                        setFormEmployee({
                                                            ...formEmployee,
                                                            firstName: event
                                                        })
                                                    }
                                                    setFieldError(`${EmployeeValidationKeys.firstName}`, error);
                                                } else {
                                                    setFormEmployee({
                                                        ...formEmployee,
                                                        firstName: event
                                                    })
                                                    clearFieldError(`${EmployeeValidationKeys.firstName}`);
                                                }
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </ErrorWrapper>
                                </div>
                            </div>

                            <div className={'employee-input-container'}>
                                <div className={'employee-div-container'}>
                                    <ErrorWrapper {...createErrorProps(`${EmployeeValidationKeys.middleName}`)}>
                                        <ARCTextInput 
                                            id={'add-user-middle-name-input'}
                                            size={'md'} 
                                            className={'employee-input-text-sm'}
                                            required={false} 
                                            value={context.demoMode ? '' : formEmployee.middleName ?? undefined}
                                            label={getLocalizedString('employee.middleName', 'Middle Initial')} 
                                            onChange={(event) => {
                                                const error = validateMiddleName(event);
                                                if (error) {
                                                    const showCharacter = nameContainsInvalidCharacters(event) && (fieldErrors[`${EmployeeValidationKeys.middleName}`]?.error?.length ?? 0) === 0;
                                                    if (showCharacter) {
                                                        setFormEmployee({
                                                            ...formEmployee,
                                                            middleName: event !== "" ? event : null
                                                        })
                                                    }
                                                    setFieldError(`${EmployeeValidationKeys.middleName}`, error);
                                                } else {
                                                    setFormEmployee({
                                                        ...formEmployee,
                                                        middleName: event !== "" ? event : null
                                                    })
                                                    clearFieldError(`${EmployeeValidationKeys.middleName}`);
                                                }
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </ErrorWrapper>
                                </div>
                            </div>

                            <div className={'employee-input-container'}>
                                <div className={'employee-div-container'}>
                                    <ErrorWrapper {...createErrorProps(`${EmployeeValidationKeys.lastName}`)}>
                                        <ARCTextInput 
                                            id={'add-user-last-name-input'}
                                            size={'md'} 
                                            required={true} 
                                            value={context.demoMode ? `${formEmployee.employeeId ?? ''}` : formEmployee.lastName}
                                            label={getLocalizedString('employee.lastName', 'Last Name')} 
                                            onChange={(event) => {
                                                const error = validateLastName(event);
                                                if(error) {
                                                    const showCharacter = nameContainsInvalidCharacters(event) && (fieldErrors[`${EmployeeValidationKeys.lastName}`]?.error?.length ?? 0) === 0;
                                                    if (showCharacter) {
                                                        setFormEmployee({
                                                            ...formEmployee,
                                                            lastName: event
                                                        })
                                                    }
                                                    setFieldError(`${EmployeeValidationKeys.lastName}`, error);
                                                } else {
                                                    setFormEmployee({
                                                        ...formEmployee,
                                                        lastName: event
                                                    })
                                                    clearFieldError(`${EmployeeValidationKeys.lastName}`);
                                                }
                                            }}
                                            disabled={!canEdit}
                                        />
                                    </ErrorWrapper>
                                </div>
                            </div>


                            <div className={'employee-section-header-border'}/>
                            <div className={'section-header employee-section-header'}>{getLocalizedString('employee.employeeGroups', 'System Access')}</div>

                            <div className={'employee-input-container'}>
                                <InputLabel className={'employee-tooltip-label'} shrink={false}>
                                    <Typography className={'employee-tooltip-label-text'}>
                                        {getLocalizedString('employee.kioskGroup', 'Kiosk Role')}
                                    </Typography>

                                    <Tooltip placement={'bottom-start'} title={
                                        <ul style={{padding: '15px', margin: '0', maxWidth: '450px'}}>
                                            <li>{getLocalizedString('employee.kioskGroup.tooltip1', 'Associates can only check out and return devices.')}</li>
                                            <li>{getLocalizedString('employee.kioskGroup.tooltip2', 'Team Leads and Managers also can access the Manager Tools Menu on kiosks. ')}</li>
                                            <li>{getLocalizedString('employee.kioskGroup.tooltip3', 'Managers have additional privileges such as overriding a locker marked as bad, or calibrating the kiosk touch screen.')}</li>
                                        </ul>
                                    }>
                                        <Error className={'employee-tooltip-icon'}/>
                                    </Tooltip>
                                </InputLabel>

                                <div className={'employee-div-container-sm'}>
                                    <ARCSelectInput
                                                id={'add-user-kiosk-group-input'}
                                                size={'md'}
                                                style={{maxWidth: '800px'}}
                                                className={'employee-input-text-md'}
                                                value={currentKioskGroup?.employeeGroupId}
                                                options={kioskGroupOptions}
                                                disabled={!canEdit}
                                                onChange={(option) => {
                                                    if (option.value !== '') {
                                                        const group = employeeGroupsMap.find(g => g.employeeGroupId === parseInt(option.value));

                                                        if (group) {
                                                            let permission;
                                                            if(group.groupName === KIOSK_EMPLOYEE) {
                                                                permission = devicePickupPermissionContext.permissions.find(p => p.default);
                                                            }

                                                            setFormEmployee({
                                                                ...formEmployee,
                                                                devicePickupPermission: permission,
                                                                employeeGroups: [
                                                                    ...formEmployee.employeeGroups.filter(group => !group.kioskGroup),
                                                                    group
                                                                ]
                                                            });
                                                        }
                                                    } else {
                                                        const group = employeeGroupsMap.find(g => g.groupName === KIOSK_NONE)

                                                        if(group) {
                                                            setUpdateBadgeId(false);
                                                            setNewBadge('');

                                                            setFormEmployee({
                                                                ...formEmployee,
                                                                devicePickupPermission: undefined,
                                                                employeeGroups: [
                                                                    ...formEmployee.employeeGroups.filter(group => !group.kioskGroup),
                                                                    group
                                                                ]
                                                            });
                                                        }
                                                    }
                                                }}
                                    />
                                </div>
                            </div>

                            <br/>

                            {context.currentBrand?.badgeValidation ?
                                <div className={`employee-badge-container ${updateBadgeId ? 'active' : ''}`}>
                                    <div className={'employee-input-container'}>
                                        <div className={'employee-badge-div'}>
                                            <ErrorWrapper {...createErrorProps(`${EmployeeValidationKeys.badgeId}`)}>
                                                <ARCTextInput
                                                    size={'md'}
                                                    prefix={context.currentBrand.employeeNumberPrefix}
                                                    tooltip={context.currentBrand.employeeNumberPrefix ? getLocalizedString('employee.badgeId.tooltip.prefix', 'Badge IDs already include the prefix ' + context.currentBrand.employeeNumberPrefix + ' by default.', [`${context.currentBrand.employeeNumberPrefix}`]) : undefined}
                                                    id="add-user-badgeid-input"
                                                    disabled={!canEdit || hasNoKioskAccess || (!!stateEmployee?.badgeId)}
                                                    value={formEmployee.badgeId}
                                                    required={!hasNoKioskAccess}
                                                    label={getLocalizedString('employee.badgeId', 'Badge ID')}
                                                    onChange={(value) => {employeeNumberOnChange(value, `${EmployeeValidationKeys.badgeId}`)}}
                                                />
                                            </ErrorWrapper>

                                            {((formEmployee.employeeId && stateEmployee?.badgeId && !hasNoKioskAccess) && !updateBadgeId) && canEdit &&
                                                <div style={{marginLeft: '25px', marginBottom: '12px'}}>
                                                    <ARCButton size={'sm'} variant={'primary'} fill={'filled'} label={getLocalizedString('edit', 'Edit')} id={'add-user-edit-badge-id-button'} onClick={() => {setUpdateBadgeId(true)}}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <br/>

                                    {(context.currentBrand?.badgeValidation) &&
                                    <>
                                        {updateBadgeId &&
                                        <>
                                            <div className={'employee-input-container'}>
                                                <div className={'employee-badge-div'}>
                                                    <ErrorWrapper {...createErrorProps(`${EmployeeValidationKeys.newBadgeId}`)}>
                                                        <ARCTextInput
                                                            size={'md'}
                                                            prefix={context.currentBrand.employeeNumberPrefix}
                                                            id="changebadge-newbadgeId"
                                                            disabled={false}
                                                            value={newBadge}
                                                            required={true}
                                                            label={getLocalizedString('employee.badgeId.newBadge', 'New Badge ID')}
                                                            onChange={(value) => {
                                                                const val = value;
                                                                const error = lockEmployeeNumber(value);
                                                                if (error) {
                                                                    const showCharacter = !isAlphanumeric(val) && (fieldErrors[`${EmployeeValidationKeys.newBadgeId}`]?.error?.length ?? 0) === 0;
                                                                    if (showCharacter) {
                                                                        setNewBadge(value.toUpperCase());
                                                                    }
                                                                    setFieldError(`${EmployeeValidationKeys.newBadgeId}`, error)
                                                                } else {
                                                                    clearFieldError(`${EmployeeValidationKeys.newBadgeId}`);
                                                                    setNewBadge(val.toUpperCase());
                                                                }
                                                            }}
                                                        />
                                                    </ErrorWrapper>

                                                    <span className={'employee-cancel-link'}
                                                                 onClick={() => {
                                                                    setUpdateBadgeId(false);
                                                                    setNewBadge('');
                                                                    clearFieldError(`${EmployeeValidationKeys.newBadgeId}`);
                                                                 }
                                                    }>
                                                        {getLocalizedString('cancel', 'Cancel')}
                                                    </span>
                                                </div>

                                                {/*<div style={{marginTop: '10px'}}>*/}
                                                {/*    <SquareSaveButtonSm onClick={() => {}}>*/}
                                                {/*        {'Save Badge ID'}*/}
                                                {/*    </SquareSaveButtonSm>*/}
                                                {/*</div>*/}

                                            </div>

                                        </>}
                                    </>
                                    }
                                    <br/>

                                </div>
                                :
                                <>
                                    <div className={'employee-input-container'}>
                                        <div className={'employee-badge-div'}>
                                            <ErrorWrapper {...createErrorProps(`${EmployeeValidationKeys.employeeNumber}`)}>
                                                <ARCTextInput
                                                    size={'md'}
                                                    prefix={context.currentBrand?.employeeNumberPrefix}
                                                    id="add-user-employee-number-input"
                                                    disabled={!canEdit || !!hasNoKioskAccess}
                                                    value={formEmployee.employeeNumber}
                                                    required={!hasNoKioskAccess}
                                                    label={getLocalizedString('employee.badgeId', 'Badge ID')}
                                                    onChange={(value) => {employeeNumberOnChange(value, `${EmployeeValidationKeys.employeeNumber}`)}}
                                                />
                                            </ErrorWrapper>
                                        </div>
                                    </div>
                                    <br/>
                                </>
                            }

                            {showDevicePickupPermissionInput &&
                                <>
                                    <EmployeeDevicePickupPermissionInput
                                        display={true}
                                        required={allowDevicePickupPermissionEdit && formEmployee.employeeGroups.some(group => group.groupName === KIOSK_EMPLOYEE)}
                                        editable={allowDevicePickupPermissionEdit}
                                        forceHideInput={forceHidePermissionInput}
                                        belowPermissions={formEmployee.employeeGroups.some(group => group.groupName === KIOSK_NONE)}
                                        abovePermissions={formEmployee.employeeGroups.some(group => group.groupName === KIOSK_TEAM_LEAD || group.groupName === KIOSK_MANAGER)}
                                        forceDefault={activeAtMultipleLocations}
                                        authLocations={context.authorizationDto?.authorizedLocations}
                                        onChange={(permission: DevicePickupPermissionDto | undefined) => {
                                            setForceHidePermissionInput(false);
                                            setFormEmployee(prevState => ({
                                                ...prevState,
                                                devicePickupPermission: permission
                                            }))
                                        }}
                                        allowMultipleCheckouts={!!context.currentBrand?.allowMultipleCheckouts}
                                        permissions={devicePickupPermissionContext.permissions}
                                        deviceTypes={deviceTypeContext.deviceTypes}
                                        deviceTypesForBrand={deviceTypeContext.brandDeviceTypes}
                                        employee={formEmployee}
                                        theme={theme}
                                        classNames={{
                                            label: 'employee-input-label',
                                            input: 'employee-input-text sm-text'
                                        }}
                                    />

                                    <SmallBr/>
                                </>
                            }

                            <div className={'employee-input-container-wide'}>
                                <div className={'employee-input-container'}>
                                    <InputLabel className={'employee-tooltip-label'} style={{marginTop: '15px', height: '30px'}} shrink={false}>
                                        <Typography className={'employee-tooltip-label-text'}>
                                            {getLocalizedString('employee.portalGroup', 'Client Portal Role')}
                                        </Typography>

                                        <Tooltip
                                            placement={'bottom-start'}
                                            title={
                                                <div style={{display: 'flex', width: '20vw', maxWidth: '250px', minWidth: '150px', flexWrap: 'wrap'}}>
                                                    {getLocalizedString('employee.portalGroup.tooltip', 'Client Portal Role is typically reserved for leaders on your team who need to manage Users and Devices in the ARC system.')}
                                                </div>
                                            }>
                                            <Error className={'employee-tooltip-icon'}/>
                                        </Tooltip>
                                    </InputLabel>

                                    <div className={'employee-div-container-sm'}>
                                        <ARCSelectInput
                                            id={'add-user-portal-group-input'}
                                            size={'md'}
                                            className={'employee-input-text-md'}
                                            style={{height: '38px', margin: '0'}}
                                            disabled={!canEdit}
                                            options={portalGroupOptions}
                                            value={currentPortalGroup?.employeeGroupId}
                                            onChange={(option) => {
                                                if(option.value) {
                                                    const group = employeeGroupsMap.find(g => g.employeeGroupId === parseInt(option.value));

                                                    if(group) {
                                                        setFormEmployee({
                                                            ...formEmployee,
                                                            employeeGroups: [
                                                                ...formEmployee.employeeGroups.filter(group => group.kioskGroup),
                                                                group
                                                            ]
                                                        });
                                                    }
                                                }
                                                else {
                                                    setShowPortalAccess(false);
                                                    setFormEmployee({
                                                        ...formEmployee,
                                                        employeeGroups: [
                                                            ...formEmployee.employeeGroups.filter(group => group.kioskGroup)
                                                        ]
                                                    })
                                                }
                                            }
                                        }/>

                                    </div>
                                </div>

                                {((context.authorizationDto?.employee ? canEdit : true)) && (formEmployee.employeeGroups.some(group => !group.kioskGroup) || showPortalAccess) &&
                                    <div className={'employee-input-container'}>
                                        <InputLabel shrink={false}
                                                    style={{marginTop: '15px', height: '30px', alignItems: 'center'}}
                                                    className={'employee-input-label'}>
                                            {getLocalizedString('employee.email', 'Email')}

                                            {formEmployee.employeeGroups.some(group => !group.kioskGroup) ?
                                            <span className={'required-star'}>{'*'}</span>
                                                :
                                                null
                                            }
                                        </InputLabel>

                                        <div className={'employee-email-container'}>
                                            <div className={'employee-email-input'}>
                                                <input
                                                   autoComplete={"email"}
                                                   id="add-user-email-input"
                                                   className={'employee-input-text'}
                                                   style={{minHeight: '38px', padding: '4px 0px 4px 8px', margin: '0'}}
                                                   required={formEmployee.employeeGroups.some(group => !group.kioskGroup)}
                                                   type={'text'}
                                                   disabled={!canEdit && !currentUserContext.groups.isArcTechSupport}
                                                   value={context.demoMode ? 'u******e@p******r.com' : formEmployee.email ?? undefined}
                                                   onChange={(event) => {
                                                       setFormEmployee({
                                                           ...formEmployee,
                                                           email: event.target.value !== "" ? event.target.value : null
                                                       })
                                                   }}
                                                />

                                                {(formEmployee.employeeId &&
                                                    formEmployee.email &&
                                                    formEmployee.employeeGroups.some(group => !group.kioskGroup)) &&

                                                formEmployee.employeePortalId ?

                                                <ColoredLink id={'add-user-reset-password-link'} className={'employee-link'} onClick={() => {
                                                    if(formEmployee.email) {
                                                        window.scrollTo(0, 0);
                                                        dispatch(forgotPassword({username: '', email: formEmployee.email, abortController}))
                                                    }
                                                }}>
                                                    {getLocalizedString('employee.resetPassword', 'Reset Password')}
                                                </ColoredLink>

                                                :

                                                formEmployee.lastPortalInviteDate &&
                                                <>
                                                        <ColoredLink id={'add-user-resend-login-email-link'} className={'employee-link'} onClick={() => {
                                                            setShowConfirmDialog(true);
                                                        }}>
                                                            {getLocalizedString('employee.sendPortalInvite', 'Resend Login Email')}
                                                        </ColoredLink>

                                                        <Dialog style={{color: GREY}}
                                                                onClose={() => setShowConfirmDialog(false)}
                                                                open={showConfirmDialog}>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description"
                                                                                   style={{color: BLACK}}>
                                                                    {getLocalizedString('employee.sendPortalInvite.confirm', 'Resend Login Email to ' + formEmployee.email + '?', [formEmployee.email ?? ''])}
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button id={'add-user-resend-login-email-popup-cancel-button'} onClick={() => setShowConfirmDialog(false)}>{getLocalizedString('no', 'No')}</Button>
                                                                <Button id={'add-user-resend-login-email-popup-submit-button'}
                                                                    onClick={() => {
                                                                    if (formEmployee.employeeId) {
                                                                        window.scrollTo(0, 0);
                                                                        dispatch(sendPortalInvite({
                                                                            employeeId: formEmployee.employeeId,
                                                                            email: formEmployee.email ?? ''
                                                                        }));
                                                                        setShowAlertOnNav(true);
                                                                        setShowConfirmDialog(false);
                                                                    }
                                                                }} autoFocus>
                                                                    {getLocalizedString('yes', 'Yes')}
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </>
                                                }
                                            </div>

                                            {formEmployee.lastPortalInviteDate &&
                                                <>
                                                    <br/>
                                                    <Typography style={{margin: 'auto'}} className={'employee-email-subtext'}>
                                                        {getLocalizedString('employee.sendPortalInvite.lastInviteDate', 'Last login email sent on ' + formEmployee.lastPortalInviteDate, [formEmployee.lastPortalInviteDate])}
                                                    </Typography>
                                                </>
                                            }
                                        </div>
                                </div>
                                }
                            </div>


                            <></>

                            <div className={'employee-section-header-border'}/>

                            <>
                                <div className={'section-header employee-section-header'}>{getLocalizedString('employee.employeeLocations', 'Location Access')}</div>

                                    <table style={{width: '50vw', minWidth: '250px', tableLayout: 'fixed', borderCollapse: 'collapse'}}>
                                            {authLocations.length > 1 &&
                                                <thead>
                                                    <th style={{width: '50px', borderBottom: 'none', display: 'flex', alignItems: 'center', padding: '8px'}}>
                                                            {
                                                                (formEmployee.employeeLocations && formEmployee.employeeLocations.some(loc => loc.active)) && !(authLocations && formEmployee.employeeLocations.filter(el => el.active).length >= authLocations.length) ?
                                                                    <ARCCheckbox
                                                                        id={'add-user-select-all-locations-checkbox'}
                                                                        size={'md'}
                                                                        indeterminate={true}
                                                                        disabled={!canEdit}
                                                                        onChange={() => checkAllLocations(false)}
                                                                        checked={true}/>
                                                                    :
                                                                    <ARCCheckbox
                                                                        size={'md'}
                                                                        id={'add-user-select-all-locations-checkbox'}
                                                                        disabled={!canEdit}
                                                                        onChange={() => checkAllLocations(!(authLocations && formEmployee.employeeLocations.filter(el => el.active).length >= authLocations.length))}
                                                                        checked={authLocations && formEmployee.employeeLocations.filter(el => el.active).length >= authLocations.length}/>
                                                            }
                                                        <Typography style={{position: 'absolute'}} className={'employee-location-header'}>{getLocalizedString('employee.employeeLocations.selectAll', 'Select all locations')}</Typography>
                                                    </th>

                                                    <th className={'employee-location-label'}>
                                                    </th>
                                                </thead>
                                            }

                                        <tbody>
                                            {authLocations?.map((location, index) => {
                                                return (
                                                    <tr className={'employee-location-row'}>
                                                        <td style={{padding: '8px'}}>
                                                            <ARCCheckbox
                                                            id={'select-location-checkbox-' + index}
                                                            size={'md'}
                                                            disabled={!canEdit}
                                                            onChange={() => employeeLocationClicked(index)}
                                                            key={index}
                                                            checked={(!!formEmployee.employeeLocations.find(l => l.location.locationId === location.locationId && l.active))}/>
                                                        </td>
                                                        <td className={'employee-location-label'} style={{fontWeight: 'bold', padding: '8px 8px 8px 0px'}}>{context.demoMode ? getLocalizedString('viewDevices.filter.location') + ' ' + location.locationId : location.locationName}</td>
                                                        {/*<TableCell>{employeeLocation.startDate && formatDate(new Date(employeeLocation.startDate), 'MM/dd/yyyy')}</TableCell>*/}
                                                        {/*<TableCell>{employeeLocation.endDate && formatDate(new Date(employeeLocation.endDate), 'MM/dd/yyyy')}</TableCell>*/}
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </>

                            <br/>

                            <Dialog style={{color: GREY}} onClose={() => setShowSendInviteDialog(false)} open={showSendInviteDialog}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" style={{color: BLACK}}>
                                        {getLocalizedString('employee.sendPortalInvite.first', 'Would you like to send a login email to ' + formEmployee.email + '?', [formEmployee.email ?? ''])}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setShowSendInviteDialog(false);
                                            submitSaveEmployee(false, false);
                                        }}>
                                        {getLocalizedString('no', 'No')}
                                    </Button>

                                    <Button onClick={() => {
                                        setShowSendInviteDialog(false);
                                        submitSaveEmployee(true, false);
                                    }} autoFocus>
                                        {getLocalizedString('yes', 'Yes')}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {formEmployee.employeeId &&
                            <>
                                <div
                                    className={'section-header employee-section-header'}>{getLocalizedString('employee.more', 'More Actions')}</div>

                                <div style={{display: 'flex', justifyContent: 'flex-start', marginBottom: '36px'}}>
                                    <ARCButton
                                        fill={'outlined'}
                                        variant={'primary'}
                                        size={'md'}
                                        id={'add-user-view-reports-button'}
                                        label={getLocalizedString('employee.reports', 'View User Insights')}
                                        onClick={() => navigate(`/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${EMPLOYEE_DASHBOARD.lookerId}/employeeId=${employeeId ?? ''}`)}
                                        icon={'graph'}
                                    >
                                    </ARCButton>
                                </div>
                            </>
                            }

                            <div className={'employee-button-div'}>
                                {(!context.authorizationDto?.employee || (canEdit || (currentUserContext.groups.isArcTechSupport && formEmployee.employeeGroups.some(group => !group.kioskGroup)))) &&
                                <ARCButton
                                    id="add-user-save-button"
                                    variant={'primary'}
                                    size={'md'}
                                    fill={'filled'}
                                    submit={true}
                                    onClick={() => {}}
                                    disabled={saveDisabled}
                                    label={getLocalizedString('employee.submit', 'Save')}
                                >
                                </ARCButton>
                                }
                            </div>

                            {/*{(formEmployee.employeeId && formEmployee.employeeHistoryList && formEmployee.employeeHistoryList?.length > 0) &&*/}
                            {/*    <div style={{width: '100%'}}>*/}
                            {/*        <Spacer/>*/}
                            {/*        <Typography className={'employee-input-label'} style={{maxWidth: '150px', minWidth:'unset'}}>{'User Update Log'}</Typography>*/}
                            {/*        <Table>*/}
                            {/*            <TableHead>*/}
                            {/*                <TableRow>*/}
                            {/*                    <UpdateLogHeaderCell>{'Date'}</UpdateLogHeaderCell>*/}
                            {/*                    <UpdateLogHeaderCell>{'Reason'}</UpdateLogHeaderCell>*/}
                            {/*                    <UpdateLogHeaderCell>{'Updated By'}</UpdateLogHeaderCell>*/}
                            {/*                </TableRow>*/}
                            {/*            </TableHead>*/}
                            
                            {/*            <TableBody>*/}
                            {/*                {formEmployee.employeeHistoryList?.map((employeeHistory, idx, {length}) => {*/}
                            {/*                return (*/}
                            {/*                     <TableRow>*/}
                            {/*                         <UpdateLogCell>{employeeHistory.changeDateTime}</UpdateLogCell>*/}
                            {/*                         <UpdateLogCell>*/}
                            {/*                                 {(!employeeHistory.reason && idx === length - 1) ? 'Record Created' :*/}
                            {/*                                 employeeHistory.reason?.split(', ').map(s => {*/}
                            {/*                                     return (*/}
                            {/*                                         <>*/}
                            {/*                                             {s}*/}
                            {/*                                             <br/>*/}
                            {/*                                         </>*/}
                            {/*                                     )*/}
                            {/*                                 })*/}
                            {/*                                 }*/}
                            {/*                         </UpdateLogCell>*/}
                            {/*                         <UpdateLogCell>{employeeHistory.changeUserName}</UpdateLogCell>*/}
                            {/*                     </TableRow>*/}
                            {/*                 )*/}
                            {/*                }*/}
                            {/*                )}*/}
                            {/*            </TableBody>*/}
                            {/*        </Table>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </Container>
                    </form>
                <Spacer/>
            </div>
        </main>
    </AppWithFooter>
);
};
