import * as React from 'react';
import AppBar from "../../../appbar/AppBar";
import {
    CircularProgress, Tooltip,
    useTheme
} from "@material-ui/core";
import {SideBar} from "../../../sidebar/SideBar";
import {
    ArrowDownward,
    ArrowUpward,
    GroupOutlined,
    UpdateOutlined
} from "@material-ui/icons";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {appContextSelector} from "../../../app-context/appContextSlice";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AppWithFooter} from "../../../../App";
import {
    CancelIconContainer,ColorHelpIcon, ErrorMessageSectionItem,
    FormTitle,
    ScalableTable
} from "../../../ui-components/StyledComponents";
import {selectLocationThunk} from "../../../app-context/thunks/SelectLocationThunk";
import {deviceTypeSelector, getDeviceTypes} from "../../deviceTypeSlice";
import {useAppViewContext} from "../../../app-context/AppViewContainer";
import {useCurrentUserContext} from "../../../app-context/CurrentUserContextContainer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DropdownMenu from "../../../ui-components/DropdownMenu";
import {
    devicePickupPermissionContextSelector,
    setDefaultPermission,
    viewAllDevicePickupPermissions
} from "../../devicePickupPermissionSlice";
import '../../../../css/DevicePickupPermissions.css';
import {LocationSelect} from "../../../ui-components/LocationSelect";
import {LocationState} from "../../../../@types/types";
import {ServerMessage} from "../../../ui-components/ServerMessage";
import {DevicePickupPermissionDto} from "../../../../dtos/DevicePickupPermission";
import {DialogAlert} from "../../../ui-components/DialogAlert";
import {calculateResponsiveSizeWithMinMax} from "../../../../util/TextUtils";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {ARCRadioInput} from "../../../ui-components/design-system/ARCRadioInput";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId} = useParams();

    React.useEffect(() => {

        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            dispatch(viewAllDevicePickupPermissions({locationId: parseInt(locationId)}));
            dispatch(getDeviceTypes({locationId: parseInt(locationId), brandId: context.currentBrand?.brandId}));

        }
        else if(context.currentLocation?.locationId) {
            dispatch(viewAllDevicePickupPermissions({locationId: context.currentLocation.locationId}));
            dispatch(getDeviceTypes({locationId: context.currentLocation?.locationId, brandId: context.currentBrand?.brandId}));
        }

    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, locationId]);
}

export const ViewDevicePickupPermissions = () => {
    useAutoSelectLocationByUrl();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const devicePickupPermissionContext = useAppSelector(devicePickupPermissionContextSelector);
    const currentUserContext = useCurrentUserContext();
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const {state} = useLocation() as LocationState;
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('xs'));

    const [sortBy, setSortBy] = React.useState<'permissionName'>('permissionName');
    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
    const [showDefaultDialog, setShowDefaultDialog] = React.useState<boolean>(false);
    const [selectedDefault, setSelectedDefault] = React.useState<DevicePickupPermissionDto>();

    const toggleDialog = React.useCallback(() => {
        setSelectedDefault(undefined);
        setShowDefaultDialog(!showDefaultDialog);
    }, [showDefaultDialog]);

    const selectNewDefault = React.useCallback((permission: DevicePickupPermissionDto) => {
        setSelectedDefault(permission);
        setShowDefaultDialog(true);
    }, []);

    const setNewDefault = React.useCallback(() => {
        if(context.currentLocation?.locationId && selectedDefault?.devicePickupPermissionId) {
            dispatch(setDefaultPermission({locationId: context.currentLocation?.locationId, devicePickupPermissionId: selectedDefault.devicePickupPermissionId}));
            setShowDefaultDialog(false);
            setSelectedDefault(undefined);
        }
    }, [context.currentLocation?.locationId, dispatch, selectedDefault?.devicePickupPermissionId]);

    const currentDefault = React.useMemo(() => {
        return devicePickupPermissionContext.permissions.find(p => p.default);
    }, [devicePickupPermissionContext.permissions]);

    const dialogTitle = React.useMemo(() => {
        if(selectedDefault && currentDefault) {
           return getLocalizedString('devicePickupPermission.changeDefault.existing', 'This will change the Default Group from ' + currentDefault.permissionName + ' to ' + selectedDefault.permissionName + '.', [`${currentDefault.permissionName}`, `${selectedDefault.permissionName}`]);
        } else {
           return getLocalizedString('devicePickupPermission.changeDefault', 'This will change the Default Group');
        }
    }, [currentDefault, selectedDefault]);

    const sortedList = React.useMemo(() => {
        return devicePickupPermissionContext.permissions.slice().sort((p1, p2) => {
            let result = p1.permissionName.localeCompare(p2.permissionName);

            return order === 'asc' ? result : result * -1;
        });
    }, [devicePickupPermissionContext.permissions, order]);

    const canEdit = React.useMemo(() => {
        return !currentUserContext.groups.isArcTechSupport && !currentUserContext.groups.isSSOHQUser;
    }, [currentUserContext.groups.isArcTechSupport, currentUserContext.groups.isSSOHQUser]);

    const getPermissionActions = React.useCallback((permission: DevicePickupPermissionDto) => {
        let actions = [];

        if(canEdit) {
            actions.push({value: `/employees/permissions/edit/devicePickupPermissionId=${permission.devicePickupPermissionId}`, label: getLocalizedString('devicePickupPermission.details', 'Details'), icon: () => <UpdateOutlined/>})
        }

        actions.push({value: `/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, label: getLocalizedString('devicePickupPermission.groupUsers', 'Group Users'), icon: () => <GroupOutlined/>});

        return actions;
    }, [canEdit, context.currentBrand?.brandId, context.currentLocation?.locationId]);

    const createDeviceTypeDisplayString = React.useCallback((permission: DevicePickupPermissionDto) => {
        let deviceTypeList: string[] = [];
        if(deviceTypeContext.deviceTypes) {
            permission.deviceTypes.forEach(dt => {
                if(deviceTypeContext.deviceTypes.find(type => type.deviceTypeId === dt.deviceTypeId)) {
                    deviceTypeList.push(dt.quantity + 'x ' + (deviceTypeContext.deviceTypes.find(type => type.deviceTypeId === dt.deviceTypeId)?.deviceTypeName ?? ' ' + getLocalizedString('devicePickupPermission.devices', 'device(s)')))
                }
            });
        } else {
            permission.deviceTypes.forEach(dt => {
                deviceTypeList.push(dt.quantity + 'x ' + getLocalizedString('devicePickupPermission.devices', 'device(s)'));
            });
        }

        let returnString = '';
        if(permission.allowMultipleCheckouts) {
            if(deviceTypeList.length > 0) {
                returnString = deviceTypeList.join(' & ');
            } else {
                returnString = '1x ' + getLocalizedString('devicePickupPermission.device', 'device');
            }
        } else {
            if(deviceTypeList.length > 0) {
                returnString = deviceTypeList.join(' or ');
            } else {
                returnString = '1x ' + getLocalizedString('devicePickupPermission.device', 'device');
            }
        }

        return returnString;
    }, [deviceTypeContext.deviceTypes]);

    const ResultMessage = React.useMemo(() => {
        if(state?.savedPermission) {
            return (
                <ServerMessage variant={'success'}>
                    {getLocalizedString('devicePickupPermission.saved', 'Permission Group Saved.')}
                </ServerMessage>
            )
        }

    }, [state?.savedPermission]);

    return (
        <AppWithFooter>
            <AppBar/>
            <SideBar/>

            <DialogAlert title={dialogTitle} messages={[getLocalizedString('devicePickupPermission.changeDefault.confirm', 'Existing users are not impacted, Do you wish to continue?')]} isOpen={showDefaultDialog} confirmButton={{id: 'pickup-permission-set-default-confirm-button', label: getLocalizedString('continue', 'Continue'), onClick: setNewDefault}} closeButton={{id: 'pickup-permission-set-default-cancel-button', label: getLocalizedString('cancel', 'Cancel'), onClick: toggleDialog}}/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                        {/*{successMessage &&*/}
                        {/*    <ServerMessage variant={'success'}>*/}
                        {/*        <SuccessMessageSectionItem>*/}
                        {/*            {successMessage}*/}
                        {/*        </SuccessMessageSectionItem>*/}
                        {/*    </ServerMessage>*/}
                        {/*}*/}

                    {devicePickupPermissionContext.permissionsErrorMessage &&
                    <ServerMessage variant={'error'}>
                        <ErrorMessageSectionItem>
                            {devicePickupPermissionContext.permissionsErrorMessage}
                        </ErrorMessageSectionItem>
                    </ServerMessage>
                    }

                    {ResultMessage}

                    <div className={'Top-Actions'}>
                        <ARCButton
                            id={'pickup-permission-create-group-button'}
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            disabled={appViewContext.brandView || !canEdit}
                            onClick={() => {navigate(`/employees/permissions/create/locationId=${context.currentLocation?.locationId}`)}}
                            icon={'add-device'}
                            label={getLocalizedString('devicePickupPermission.addDevicePickupPermission', 'Create New Group')}
                        >
                        </ARCButton>
                    </div>


                    <FormTitle variant='h5' className={'o-heading--xl'}>{getLocalizedString('devicePickupPermission.title', 'Device Permissions Groups')}</FormTitle>

                    <div className={'Top-Actions'}>
                        <div className={'Centered-Container'}>
                            <span>
                                {getLocalizedString('devicePickupPermission.location', 'Location Name')}
                            </span>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <LocationSelect id={'pickup-permission-location-filter-input'} showAllLocations={false}/>
                                <CancelIconContainer/>
                            </div>
                        </div>
                    </div>

                    <ScalableTable>
                        <thead style={{borderBottom: '2px solid black'}}>
                            <tr>
                                {canEdit &&
                                    <th className={'Header-Table-Cell'} style={{width: '10vw'}} onClick={() => {
                                    }}>
                                    <span className={'Header-Table-Content'}>
                                        <Tooltip placement={'top-start'}
                                                 title={getLocalizedString('devicePickupPermission.tooltip', 'Simplify activating new users by setting the Default Group to the most common Device Permissions group. A user’s group can always be changed.')}>
                                            <ColorHelpIcon
                                                style={{fontSize: calculateResponsiveSizeWithMinMax('3', '24', '10')}}/>
                                        </Tooltip>

                                        {getLocalizedString('devicePickupPermission.default', 'Default')}
                                    </span>
                                    </th>
                                }

                                <th className={'Header-Table-Cell'} onClick={() => {
                                    setOrder(order === 'asc' && sortBy === 'permissionName' ? 'desc' : 'asc');
                                    setSortBy('permissionName');
                                }}>
                                    <span className={'Header-Table-Content'}>
                                        {getLocalizedString('devicePickupPermission.permissionName', 'Group Name')}
                                        {sortBy === 'permissionName' ?
                                            (order === 'asc' ? 
                                                <ArrowUpward style={{fontSize: calculateResponsiveSizeWithMinMax('3', '24', '10')}}/> : 
                                                <ArrowDownward style={{fontSize: calculateResponsiveSizeWithMinMax('3', '24', '10')}}/>
                                            ) : 
                                            <ArrowUpward style={{visibility: 'hidden'}}/>}
                                    </span>
                                </th>

                                {!isSmOrBelow &&
                                <th className={'Header-Table-Cell'} onClick={() => {
                                }}>
                                    <span className={'Header-Table-Content'}>
                                        {getLocalizedString('devicePickupPermission.permissionDeviceTypes', 'Device Permissions')}
                                    </span>
                                </th>
                                }

                                {!isSmOrBelow &&
                                <th className={'Header-Table-Cell'} onClick={() => {
                                }}>
                                    <span className={'Header-Table-Content'}>
                                        {getLocalizedString('devicePickupPermission.activeUsers', 'Active Users')}
                                    </span>
                                </th>
                                }

                                <th className={'Header-Table-Cell'} style={{minWidth: '150px', maxWidth: '200px'}}>
                                    <span className={'Header-Table-Content'}>
                                        {getLocalizedString('devicePickupPermission.actions', 'Actions')}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            { devicePickupPermissionContext.viewAllPermissionsLoading ?
                                <tr className={'Permission-Table-Row'}>
                                    {canEdit && <td className={'Permission-Table-Cell'}/>}
                                    <td className={'Permission-Table-Cell'} style={{width: '10vw'}}/>
                                    <CircularProgress style={{marginTop: '10vh'}} size={100}/>
                                    <td className={'Permission-Table-Cell'} style={{width: '20vw'}}/>
                                </tr>
                                :
                                sortedList.map((permission, idx) => {
                                    return (
                                        <tr key={idx} className={'Permission-Table-Row'}>
                                            {canEdit &&
                                                <td className={'Permission-Table-Cell Permission-Center-Table-Cell'}>
                                                    <ARCRadioInput
                                                        id={'set-default-group-radio-' + idx}
                                                        size={'lg'}
                                                        on={permission.default}
                                                        onChange={() => selectNewDefault(permission)}/>
                                                </td>
                                            }

                                            <td className={'Permission-Table-Cell'}>
                                                {permission.permissionName}
                                            </td>

                                            {!isSmOrBelow &&
                                            <td className={'Permission-Table-Cell'}>
                                                {createDeviceTypeDisplayString(permission)}
                                            </td>
                                            }

                                            {!isSmOrBelow &&
                                                <td className={'Permission-Table-Cell'}>
                                                    {permission.activeEmployeeCount ?? '0'}
                                                </td>
                                            }

                                            <td className={'Permission-Table-Cell'} style={{overflow: 'unset', display: 'flex', alignItems: 'baseline'}}>
                                                <DropdownMenu
                                                    label={'...'}
                                                    id={"device-pickup-action-dropdown-" + idx}
                                                    isSmOrBelow={isSmOrBelow}
                                                    onChange={(option) => {
                                                        navigate(option.value, {state: {devicePickupPermissionId: permission.devicePickupPermissionId}});
                                                    }}
                                                    options={getPermissionActions(permission)}
                                                />
                                            </td>
                                        </tr>
                                    )
                            })}

                        </tbody>
                    </ScalableTable>

                </div>
            </main>
        </AppWithFooter>
    );
};
